import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { useRouteName, useRouteDataRef } from '@confluence/route-manager';
import { PEOPLE_DIRECTORY, CONFLUENCE_PEOPLE_DIRECTORY_SEARCH } from '@confluence/named-routes';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { SSRMouseEventWrapper, SSR_NAV_PEOPLE_BUTTON_METRIC } from '@confluence/browser-metrics';

import { TeamsIconComponent } from '../GlobalNavigationIcons';
import { i18n } from '../globalNavigationTranslations';

import { useGlobalItemVisibility } from './useGlobalItemVisibility';
import type { GlobalItemProps } from './globalItemProps';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireTeamsClickedAnalytics,
);

const isOnPeopleRouteArgs = {
	selector: (routeName: string | undefined) => {
		if (!routeName) {
			return false;
		}
		return routeName === PEOPLE_DIRECTORY.name;
	},
};

export const TeamsItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const routeDataRef = useRouteDataRef();
	const isSelected = useRouteName(isOnPeopleRouteArgs);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickedAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const shouldHide = useGlobalItemVisibility(
		'teams',
		isSelected,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_PEOPLE_BUTTON_METRIC}>
			<MenuLinkItem
				href={CONFLUENCE_PEOPLE_DIRECTORY_SEARCH.toUrl()}
				isSelected={isSelected}
				elemBefore={TeamsIconComponent}
				onClick={fireClickedAnalytics}
			>
				<FormattedMessage {...i18n.teams} />
			</MenuLinkItem>
		</SSRMouseEventWrapper>
	);
};
